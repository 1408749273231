import React from "react"
import { Link } from "gatsby"


const Pager = ({ pageContext }) => {
  const { previousPagePath, nextPagePath, numberOfPages } = pageContext
  const pages = Array.from({ length: numberOfPages }, () => [])

  return (
    <div className='pager'>
      {previousPagePath && (
          <Link className='pager__page' to={previousPagePath}>Previous</Link>
      )}

      {pages.map((page, index) => {
        return (
          <Link className='pager__page' key={index} to={index > 0 ? `/blog_archive/${index + 1}` : "/blog_archive/"}>
            {index + 1}
          </Link>
        )
      })}

      {nextPagePath && (
          <Link  className='pager__page' to={nextPagePath}>Next</Link>
      )}
    </div>
  )
}

export default Pager
