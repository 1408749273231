import React from "react"
import { Link } from "gatsby"
import imgDefault from '../../../images/blog/default.jpg'



const Card = ({ post, tab }) => {
  return (
    <div className='post'>
      <Link to={"/blog/posts/" + post.slug}>
        <div className="post__featured-image">
          <img src={post.featuredImage && post.featuredImage || imgDefault} />
        </div>
      </Link>

      {
        tab && (
          <Link to={"/blog/tabs/" + tab.slug}>
            <p>{tab.title}</p>
          </Link>
        )
      }


      <Link to={"/blog/posts/" + post.slug}>
        <h3 className='post__title'>{post.title}</h3>
      </Link>

      <p className='post__summary'>{post.summary}</p>

      <div className="post__meta">
        {
          post.author?.slug ? (
            <Link className='post__meta__author' to={"/blog/authors/" + post.author.slug}>
              <img src={post.author.photo} />
              <p>{post.author.name}·&ndash; {post.publishedAt}</p>
            </Link>
          ) : <p className='post__meta__author'>{post.publishedAt}</p>
        }
        <p className='post__meta__readtime'>{post.readtime}</p>
      </div>
    </div>
  )
}

export default Card
