import React from "react"
import { Link } from "gatsby"


const BlogNav = () => {

  return (
    <ul className='blog-nav'>
      <li><Link to="/blog">Blog Home</Link></li>
      <li><Link to="/blog_authors">Authors</Link></li>
      <li><Link to="/blog_archive">Archive</Link></li>
    </ul>
  )
}

export default BlogNav
